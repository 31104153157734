import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect, useParams, useRouteMatch } from "react-router-dom";
import { useUser } from "reactfire";
import reactModal from "@prezly/react-promise-modal";
import NotFound from "./NotFound";
import { clearKeepAliveListeners } from "../hooks/firebase";

import { actions, Context } from "../store";

// Routes
import { DefaultLayout, titleTemplate, defaultRoute, routes } from "../routes";
import { createModal } from "./Utils";

// ---
// Main route function

function CustomBusinessRoute({ location }) {
  const [, dispatch] = useContext(Context);
  let { businessId } = useParams();
  const { data: user } = useUser();
  const match = useRouteMatch();

  useEffect(() => {
    console.log("Setting business to ", businessId);
    clearKeepAliveListeners();
    dispatch({
      type: actions.SET_REQUESTED_BUSINESS,
      bid: businessId,
    });
  }, [businessId, dispatch]);

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {
            from: {
              ...location,
              pathname: location.pathname.replace(match.url, ""),
            },
          },
        }}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: location.pathname.replace(match.url, ""),
      }}
    />
  );
}

const updatedRoutes = routes.map(route => {
  route.layout = route.layout || DefaultLayout;
  route.exact = route.exact ?? true;
  route.private = route.private ?? true; // default to private
  route.scrollToTop = route.scrollToTop ?? true;
  return route;
});

// Set app loading class
document.documentElement.classList.add("app-loading");

function Router(props) {
  const routes = updatedRoutes;
  const [state] = useContext(Context);
  const { data: user } = useUser();

  const setTitle = title => {
    document.title = titleTemplate.replace("%s", title);
  };

  const scrollTop = (to, duration, element = document.scrollingElement || document.documentElement) => {
    if (element.scrollTop === to) return;
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    if (!duration) {
      element.scrollTop = to;
      return;
    }

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  };

  const getUserConfirmation = async (message, callback) => {
    const deletionModal = createModal({ body: message });
    callback(await reactModal(deletionModal));
  };

  const logout = props.logout;

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME} getUserConfirmation={getUserConfirmation}>
      <Switch>
        <Route path="/b/:businessId" render={props => <CustomBusinessRoute {...props} />} />
        {routes.map(route => {
          return (
            <Route
              path={route.path}
              exact={route.exact}
              render={props => {
                if (route.private && !user) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: props.location },
                      }}
                    />
                  );
                }
                if (route.path === "/login" && user && state.uid) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/",
                      }}
                    />
                  );
                }
                if (
                  user &&
                  state.uid &&
                  !state.token?.claims.admin &&
                  state.business &&
                  state.business.id &&
                  !state.business.businessMode &&
                  route.path !== "/business/mode"
                ) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/business/mode",
                      }}
                    />
                  );
                }

                // On small screens collapse sidenav
                if (window.layoutHelpers && window.layoutHelpers.isSmallScreen()) {
                  window.layoutHelpers.setCollapsed(true, false);
                }

                // Scroll page to top on route render
                if (route.scrollToTop) {
                  scrollTop(0, 0);
                }

                // Set title if available
                if (route.title) {
                  setTitle(route.title);
                }

                // Return layout
                return (
                  <route.layout {...props} logout={logout}>
                    <route.component
                      {...props}
                      logout={logout}
                      title={route.title}
                      setTitle={setTitle}
                      scrollTop={scrollTop}
                    />
                  </route.layout>
                );
              }}
              key={route.path}
            />
          );
        })}
        {defaultRoute !== "/" && <Redirect from="/" to={defaultRoute} exact={true} />}

        {/* NotFound page */}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
