import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";
import auth from "firebase/auth";
import { Business, BusinessSettings, User } from "../types";
import { DateTime } from "luxon";

export interface MainState {
  uid: string | null;
  currentBid: string | null;
  user: Partial<User>;
  businessIds: string[];
  business: Partial<Business & { id: string }>;
  businessSettings: Partial<BusinessSettings>;
  businesses: { [key: string]: Partial<Business> };
  token: auth.IdTokenResult | null;
  startDate: number;
  endDate: number;
  showBusinessPicker: boolean;
}

export const initialState: MainState = {
  uid: null,
  currentBid: null,
  user: {},
  businessIds: [],
  business: {},
  businessSettings: {},
  businesses: {},
  token: null,
  startDate: DateTime.now().minus({ month: 1 }).startOf("day").valueOf(),
  endDate: DateTime.now().startOf("day").valueOf(),
  showBusinessPicker: false,
};

const Store = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};
export const Context = createContext<[MainState, React.Dispatch<any>]>([initialState, () => null]);
export * from "./actions";
export default Store;
