import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useDatabase } from "reactfire";
import { ref as dbRef } from "firebase/database";
import { clearKeepAliveListeners } from "../../hooks/firebase";
import { actions, Context } from "../../store";

import { Business, BusinessUser } from "../../types";
import { useDatabaseObjectData } from "../../hooks/firebase";

import styles from "./BusinessSwitcher.module.scss";

type BusinessPanelProps = {
  business: Partial<Business>;
  bid: string;
  uid: string;
};
function BusinessPanel({ business, bid, uid }: BusinessPanelProps) {
  const database = useDatabase();
  const userRef = dbRef(database, `/businessData/${bid}/users/${uid}/`);
  const user = useDatabaseObjectData<BusinessUser>(userRef, { errorOnEmpty: false });

  if (!business) {
    return null;
  }
  return (
    <div className={styles.businessInner}>
      {business?.logo_thumbnail && (
        <img className={`${styles.businessImage} rounded-circle`} src={business?.logo_thumbnail} alt={business?.name} />
      )}
      <div className="business-title">
        <b>{business?.name}</b>
        {business.owner === uid && (
          <>
            <br />
            Owner
          </>
        )}
        {user.status === "success" && (
          <>
            <br />
            {user.data?.roleName || user.data?.role}
          </>
        )}
      </div>
    </div>
  );
}

export default function BusinessSwitcher() {
  const [state, dispatch] = useContext(Context);

  function setBusiness(businessId: string) {
    console.log("Setting business to ", businessId);
    clearKeepAliveListeners();
    dispatch({
      type: actions.SET_REQUESTED_BUSINESS,
      bid: businessId,
    });
  }

  if (!state.uid) {
    return null;
  }

  return (
    <Modal
      show={state.showBusinessPicker}
      backdrop={state.currentBid ? true : "static"}
      onHide={() => dispatch({ type: actions.HIDE_BUSINESS_PICKER })}
      centered={true}
      scrollable={true}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>Choose Business</Modal.Title>
      </Modal.Header>
      <ul className={`list-group list-group-flush ${styles.switcher}`}>
        {Object.keys(state.businesses).map(bid => {
          return (
            <button
              key={bid}
              onClick={() => setBusiness(bid)}
              className={`list-group-item list-group-item-action ${bid === state.currentBid ? "active" : ""}`}
            >
              <BusinessPanel business={state.businesses[bid]} bid={bid} uid={state.uid!} />
            </button>
          );
        })}
      </ul>
    </Modal>
  );
}
