import React, { useContext } from "react";
import Sidenav from "../../vendor/libs/sidenav";
import { sidenavBg } from "./Config";
import { actions, Context } from "../../store";

import { getPath } from "../../routes";

import "./LayoutSidenav.scss";
import { StringsContext } from "../../strings";
import { PermissionsContext } from "../../permissions";
import { Link, useHistory } from "react-router-dom";

function LayoutSidenav({ orientation = "vertical", location, sidenavToggle }) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const { permissions, role, roleName } = useContext(PermissionsContext);
  const { tl } = useContext(StringsContext);

  function layoutSidenavClasses() {
    let bg = sidenavBg;

    if (orientation === "horizontal" && (bg.indexOf(" sidenav-dark") !== -1 || bg.indexOf(" sidenav-light") !== -1)) {
      bg = bg.replace(" sidenav-dark", "").replace(" sidenav-light", "").replace("-darker", "").replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (orientation !== "horizontal" ? "layout-sidenav" : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  }

  /**
   * @param {Business} business
   * @param {User} user
   */
  function businessPanel(business, roleName) {
    if (!business) {
      return "";
    }
    if (!roleName) {
      roleName = business.owner === state.uid ? "Owner" : business.users?.[state.uid]?.roleName;
    }
    return (
      <>
        {business?.logo ? (
          <img
            className="app-brand-logo business-image rounded-circle"
            src={business?.logo_thumbnail || business?.logo}
            alt={business?.name}
          />
        ) : (
          <svg className="app-brand-logo" width="0" height="0"></svg>
        )}
        <div className="app-brand-text business-title">
          <b>{business?.name}</b>
          {roleName && (
            <>
              <br />
              {roleName}
            </>
          )}
        </div>
      </>
    );
  }

  const switchToMyBusiness = () => {
    if (state.businessIds.length === 1) {
      return history.push(`/b/${state.businessIds[0]}`);
    } else {
      return dispatch({
        type: actions.SHOW_BUSINESS_PICKER,
      });
    }
  };

  const showInvoices = ["invoicing", "hybrid", "hospitality"].includes(state.business.businessMode);
  const showManageMenu = [
    "manageCustomers",
    "manageItems",
    "manageCategories",
    "manageDiscounts",
    "manageSurcharges",
    "manageModifiers",
  ].some(p => permissions[p]);
  const showBusinessMenu =
    role === "owner" || ["manageBusiness", "manageBusinessMode", "manageUsers"].some(p => permissions[p]);
  const isAdmin = !!state.token?.claims.admin;

  return (
    <Sidenav orientation={orientation} className={layoutSidenavClasses()}>
      {/* Inner */}
      <div className="app-brand business-panel">{businessPanel(state.business, roleName)}</div>
      <Sidenav.Divider className="mt-0" />
      <div className="sidenav-inner w-100">
        {(state.business.businessMode || state.token?.claims.admin) && (
          <>
            {state.token?.claims.admin && (
              <>
                <Sidenav.Header className="small font-weight-semibold">{tl("Administration")}</Sidenav.Header>
                {!state.businessIds.includes(state.currentBid) && (
                  <Link variant="link" className="sidenav-item d-block" onClick={switchToMyBusiness}>
                    <div className="sidenav-link">
                      <i className="sidenav-icon fas fa-fw fa-sync-alt"></i>
                      <div>{tl("Switch to my Business")}</div>
                    </div>
                  </Link>
                )}
                <Sidenav.RouterLink
                  role="navigation"
                  to={getPath("businessSearch")}
                  exact={true}
                  icon="fas fa-fw fa-search"
                >
                  {tl("Business Search")}
                </Sidenav.RouterLink>
                <Sidenav.Divider />
              </>
            )}
            <nav className="w-100" role="navigation" aria-label="main">
              <ul className="list-unstyled list-inline">
                <li>
                  <Sidenav.RouterLink to={getPath("home")} exact={true} icon="fas fa-fw fa-home">
                    Home
                  </Sidenav.RouterLink>
                </li>
                <li>
                  {showInvoices && (
                    <Sidenav.RouterLink
                      to={getPath("invoices")}
                      exact={false}
                      icon={`fas fa-fw fa-file-invoice-dollar`}
                    >
                      {tl("Invoices")}
                    </Sidenav.RouterLink>
                  )}
                </li>
                <li>
                  <Sidenav.RouterLink to={getPath("paid")} exact={false} icon={`fas fa-fw fa-receipt`}>
                    {tl("Paid")}
                  </Sidenav.RouterLink>
                </li>
              </ul>
            </nav>
            <Sidenav.Divider />
            {(permissions.viewReports || isAdmin) && (
              <nav className="w-100" role="navigation" aria-labelledby="reports-label">
                <Sidenav.Header id="reports-label" className="small font-weight-semibold">
                  {tl("Reports")} {!permissions.viewReports && <i className="fas fa-lock-open"></i>}
                </Sidenav.Header>
                <ul className="list-unstyled list-inline">
                  <li>
                    <Sidenav.RouterLink to={getPath("reportSummary")} exact={false} icon={`fas fa-fw fa-chart-line`}>
                      {tl("Summary")}
                    </Sidenav.RouterLink>
                  </li>
                  <li>
                    <Sidenav.RouterLink to={getPath("payments")} exact={false} icon={`fas fa-fw fa-credit-card`}>
                      {tl("Payments")}
                    </Sidenav.RouterLink>
                  </li>
                </ul>
                <Sidenav.Divider />
              </nav>
            )}
            {(showManageMenu || isAdmin) && (
              <nav className="w-100" role="navigation" aria-labelledby="inventory-customers-label">
                <Sidenav.Header id="inventory-customers-label" className="small font-weight-semibold">
                  {permissions.manageCustomers ? tl("Inventory & Customers") : tl("Inventory")}{" "}
                  {!showManageMenu && <i className="fas fa-lock-open"></i>}
                </Sidenav.Header>
                <ul className="list-unstyled list-inline">
                  <li>
                    {(permissions.manageCustomers || isAdmin) && (
                      <Sidenav.RouterLink
                        to={getPath("contacts")}
                        exact={false}
                        icon={`fas fa-fw ${tl("fa-address-book")}`}
                      >
                        {tl("Customers")} {!permissions.manageCustomers && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {(permissions.manageItems || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("products")} exact={false} icon="fas fa-fw fa-shopping-bag">
                        {tl("Products")} {!permissions.manageItems && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {(permissions.manageCategories || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("categories")} exact={false} icon="fas fa-fw fa-folder-open">
                        {tl("Categories")} {!permissions.manageCategories && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {(permissions.manageDiscounts || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("discounts")} exact={false} icon="fas fa-fw fa-badge-percent">
                        {tl("Discounts")} {!permissions.manageDiscounts && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {(permissions.manageSurcharges || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("surcharges")} exact={false} icon="fas fa-fw fa-percentage">
                        {tl("Surcharges")} {!permissions.manageSurcharges && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {(permissions.manageModifiers || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("modifiers")} exact={false} icon="fas fa-fw fa-search-plus">
                        {tl("Modifiers")} {!permissions.manageModifiers && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                </ul>
                <Sidenav.Divider />
              </nav>
            )}
            {(showBusinessMenu || isAdmin) && (
              <nav className="w-100" role="navigation" aria-labelledby="business-settings-label">
                <Sidenav.Header id="business-settings-label" className="small font-weight-semibold">
                  {tl("Business Settings")} {!showBusinessMenu && <i className="fas fa-lock-open"></i>}
                </Sidenav.Header>
                <ul className="list-unstyled list-inline">
                  <li>
                    {(permissions.manageBusiness || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("businessDetails")} exact={true} icon="fas fa-fw fa-building">
                        Business Details {!permissions.manageBusiness && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {(permissions.manageBusinessMode || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("businessMode")} exact={true} icon="fas fa-fw fa-suitcase">
                        Business Mode {!permissions.manageBusinessMode && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {(permissions.manageUsers || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("businessUsers")} exact={true} icon="fas fa-fw fa-user-friends">
                        Additional Users {!permissions.manageUsers && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                  <li>
                    {((permissions.managePermissions && role === "owner") || isAdmin) && (
                      <Sidenav.RouterLink to={getPath("permissions")} exact={true} icon="fas fa-fw fa-user-lock">
                        Roles &amp; Features {!permissions.managePermissions && <i className="fas fa-lock-open"></i>}
                      </Sidenav.RouterLink>
                    )}
                  </li>
                </ul>
              </nav>
            )}
            <nav className="w-100" role="navigation" aria-labelledby="account-data-label">
              <Sidenav.Header id="account-data-label" className="small font-weight-semibold">
                {tl("Account & Data")}
              </Sidenav.Header>
              <ul className="list-unstyled list-inline">
                {state.currentBid && state.token?.claims?.adminAccess?.b !== state.currentBid && (
                  <li>
                    <Sidenav.RouterLink to={getPath("security")} exact={true} icon="fas fa-fw fa-user-shield">
                      Account Security
                    </Sidenav.RouterLink>
                  </li>
                )}
                <li>
                  {(role === "owner" || isAdmin) && (
                    <Sidenav.RouterLink to={getPath("data")} exact={true} icon="fas fa-fw fa-database">
                      Data Management {role !== "owner" && <i className="fas fa-lock-open"></i>}
                    </Sidenav.RouterLink>
                  )}
                </li>
              </ul>
              <Sidenav.Divider />
            </nav>

            <nav className="w-100" role="navigation" aria-label="footer">
              <ul className="list-unstyled list-inline">
                <li>
                  <Sidenav.Link
                    href={process.env.REACT_APP_HELP_LINK}
                    target="_blank"
                    icon="fas fa-fw fa-question-circle"
                    rel="noreferrer"
                    role="navigation"
                    aria-label="Airpay POS help"
                  >
                    Help <i className="fas fa-fw fa-external-link-alt"></i>
                  </Sidenav.Link>
                </li>
                <li>
                  <Sidenav.RouterLink className="w-100" to={getPath("about")} exact={true} icon="fas fa-fw fa-info">
                    About {tl("Airpay POS")}
                  </Sidenav.RouterLink>
                </li>
                <li>
                  <div className="quest-logo" role="navigation">
                    <a href="https://questps.com.au/" target="_blank" rel="noreferrer">
                      <img
                        src={`/assets/img/${process.env.REACT_APP_SIDE_LOGO || "quest-logo.png"}`}
                        className="img-fluid"
                        alt="Quest Payment Systems"
                      />
                    </a>
                  </div>
                </li>
              </ul>
              <Sidenav.Divider />
            </nav>
          </>
        )}
      </div>
    </Sidenav>
  );
}

export default LayoutSidenav;
