import { DatabaseReference, Query as DatabaseQuery, onValue } from "firebase/database";
import { useEffect, useState } from "react";

let KeepAliveListeners: {
  ref: DatabaseReference | DatabaseQuery;
  unsubscribe: () => void;
}[] = [];

function keepQueryAlive(ref: DatabaseReference | DatabaseQuery) {
  if (KeepAliveListeners.find(listener => listener.ref.isEqual(ref))) {
    return;
  }

  // Empty listener to keep it running
  console.info(`Subscribing keep-alive for ${String(ref)}`);
  const unsubscribe = onValue(ref, () => {});
  KeepAliveListeners.push({
    ref,
    unsubscribe,
  });
}

export function clearKeepAliveListeners() {
  let listener;
  while (!!(listener = KeepAliveListeners.pop())) {
    console.info(`Unsubscribing keep-alive from ${String(listener.ref)}`);
    listener.unsubscribe();
  }
}

type Options = {
  idField?: string;
  keepAlive?: boolean;
  errorOnEmpty?: boolean;
};

export function useDatabaseObjectData<T = unknown>(
  ref: DatabaseReference | DatabaseQuery,
  options: Options = {},
  isList = false
) {
  const [currentRef, setCurrentRef] = useState(ref);
  const [status, setStatus] = useState<"loading" | "error" | "success">("loading");
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error>();

  const errorOnEmpty = options.errorOnEmpty ?? true;

  useEffect(() => {
    if (currentRef && !currentRef.isEqual(ref)) {
      setCurrentRef(ref);
    }
  }, [ref, currentRef]);

  useEffect(() => {
    console.info(`Subscribing to ${String(currentRef)}`);
    if (options.keepAlive) {
      keepQueryAlive(currentRef);
    }
    const unsubscribe = onValue(
      currentRef,
      snapshot => {
        let newData = snapshot.val();
        if (!newData) {
          if (errorOnEmpty) {
            setData(undefined);
            setError(new Error("No data found."));
            setStatus("error");
            return;
          } else {
            newData = {};
          }
        }
        if (options.idField) {
          newData[options.idField] = currentRef.ref.key;
        }
        setData(newData);
        setStatus("success");
      },
      error => {
        console.error(`Error with subscription to ${String(currentRef)}`, error);
        setError(error);
        setStatus("error");
      }
    );
    return () => {
      console.info(`Unsubscribing from ${String(currentRef)}`);
      unsubscribe();
    };
  }, [currentRef, options.idField, options.keepAlive, errorOnEmpty]);

  return { status, data, error };
}

export function useDatabaseListData<T = unknown, ID = undefined>(
  ref: DatabaseReference | DatabaseQuery,
  options: Options = {}
) {
  type DataType = ID extends string ? T & { [key in ID]: string } : T;

  const [currentRef, setCurrentRef] = useState(ref);
  const [status, setStatus] = useState<"loading" | "error" | "success">("loading");
  const [data, setData] = useState<DataType[]>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (currentRef && !currentRef.isEqual(ref)) {
      setCurrentRef(ref);
    }
  }, [ref, currentRef]);

  // NOTE: Queries that have have the same location, no limit, but different ordering
  // seem to be smart enough to only hit the database once!!
  // This is good but we should consider making keepQueryAlive a bit smarter to
  // handle this (though it works fine just now)

  useEffect(() => {
    console.info(`Subscribing to ${String(currentRef)}`);
    if (options.keepAlive) {
      keepQueryAlive(currentRef);
    }
    const unsubscribe = onValue(
      currentRef,
      snapshot => {
        const newList: DataType[] = [];
        snapshot.forEach(child => {
          const obj = child.val();
          if (options.idField) {
            obj[options.idField] = child.key;
          }
          newList.push(obj);
        });
        setData(newList);
        setStatus("success");
      },
      error => {
        console.error(`Error with subscription to ${String(currentRef)}`, error);
        setStatus("error");
        setError(error);
      }
    );
    return () => {
      console.info(`Unsubscribing from ${String(currentRef)}`);
      unsubscribe();
    };
  }, [currentRef, options.idField, options.keepAlive]);

  return { status, data, error };
}
